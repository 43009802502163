export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [];

export const dictionary = {
		"/": [9],
		"/admin": [10,[2]],
		"/admin/organizations": [11,[2]],
		"/admin/organizations/[id]": [12,[2]],
		"/admin/page/[page]": [13,[2]],
		"/admin/tree": [14,[2]],
		"/admin/users": [15,[2]],
		"/admin/users/[id]": [16,[2]],
		"/organization/[org]": [17,[3]],
		"/organization/[org]/addresses": [18,[3]],
		"/organization/[org]/documents": [19,[3]],
		"/organization/[org]/page/[page]": [20,[3]],
		"/organization/[org]/settings": [21,[3]],
		"/organization/[org]/tree": [22,[3]],
		"/organization/[org]/users": [23,[3]],
		"/password-reset": [24,[4]],
		"/password-reset/[code]": [25,[4]],
		"/signin": [26,[5]],
		"/signup": [27,[6]],
		"/sprites": [28],
		"/user": [29,[7]],
		"/user/create-organization": [30,[7]],
		"/user/documents": [31,[7]],
		"/user/settings": [32,[7]],
		"/verification": [33,[8]],
		"/verification/[code]": [34,[8]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';